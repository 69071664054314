var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"sm":"10"}},[_c('Heading',{attrs:{"title":"Цены"}})],1),_c('v-col',{attrs:{"align":"right","align-self":"center"}},[_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":_vm.onAddClick}},[_vm._v(" Добавить ")])],1)],1),(!_vm.isLoaded)?_c('v-skeleton-loader',{attrs:{"loading":!_vm.isLoaded,"type":"table-tbody"}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.ferryPrice.id)?_c('v-text-field',{attrs:{"label":"Цена парома, Астрахань","value":_vm.ferryPrice.price,"disabled":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"warning","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.onEditClick(_vm.ferryPriceId)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]},proxy:true}],null,false,1282391212)}):_vm._e()],1)],1),_c('v-data-table',{attrs:{"items":_vm.prices,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.region_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.region.name)+" ")]}},{key:"item.district_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.district ? item.district.name : '-')+" ")]}},{key:"item.job_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job_type.name)+" ")]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.name)+" ")]}},{key:"item.meter_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.meter ? item.meter.name : '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"warning","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.onEditClick(item.id)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.onDeleteClick(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])}),_c('PriceModal',{attrs:{"price":_vm.modalItem,"is-ferry":_vm.modalItem && _vm.ferryPriceId === _vm.modalItem.id},on:{"close":_vm.onModalClose}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.priceDeleteDialog),callback:function ($$v) {_vm.priceDeleteDialog=$$v},expression:"priceDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Вы уверены? ")]),_c('v-card-text',[_vm._v(" Вы уверены что хотите удалить цену? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.priceDeleteDialog = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deletePrice}},[_vm._v(" Удалить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }