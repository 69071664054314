<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit">
          <v-card-text>
            <v-select
              label="Регион"
              required
              v-model="modalPrice.region_id"
              :items="regions"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.region_id"
              :disabled="isFerry"
            />

            <v-select
              v-if="!isFerry"
              label="Район"
              required
              v-model="modalPrice.district_ids"
              :items="filteredDistricts"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.district_ids"
              :disabled="!modalPrice.region_id"
              multiple
            />

            <v-select
              v-if="!isFerry"
              label="Тип"
              required
              v-model="modalPrice.type_id"
              :items="types"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.type_id"
              :disabled="!modalPrice.region_id || modalPrice.district_ids.length === 0"
            />

            <v-select
              v-if="!isFerry"
              label="Тип работы"
              required
              v-model="modalPrice.job_type_id"
              :items="job_types"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.job_type_id"
              :disabled="!modalPrice.region_id || modalPrice.district_ids.length === 0 || !modalPrice.type_id"
            />

            <v-select
              v-if="!isFerry"
              label="Тип счётчика"
              required
              v-model="modalPrice.meter_ids"
              :items="filteredMeters"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.meters"
              :disabled="!modalPrice.region_id || modalPrice.district_ids.length === 0 || !modalPrice.type_id || !modalPrice.job_type_id"
              multiple
            />

            <v-text-field
              label="Цена, руб"
              required
              type="number"
              v-model="modalPrice.price"
              @input="resetErrors"
              :error-messages="errors.price"
            />

            <v-text-field
              v-if="!isFerry"
              label="Оплата менеджера, руб"
              required
              type="number"
              v-model="modalPrice.price_manager"
              @input="resetErrors"
              :error-messages="errors.price_manager"
            />

            <v-text-field
              v-if="!isFerry"
              label="Оплата слесаря, руб"
              required
              type="number"
              v-model="modalPrice.price_mechanic"
              @input="resetErrors"
              :error-messages="errors.price_mechanic"
            />

            <v-text-field
              v-if="!isFerry"
              label="Оплата поверителя, руб"
              required
              type="number"
              v-model="modalPrice.price_verifier"
              @input="resetErrors"
              :error-messages="errors.price_verifier"
            />

            <v-text-field
              v-if="!isFerry"
              label="Оплата водителя, руб"
              required
              type="number"
              v-model="modalPrice.price_driver"
              @input="resetErrors"
              :error-messages="errors.price_driver"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>

            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListsService, PricesService } from '@/common/api.service'

export default {
  props: {
    price: {
      id: 0,
      default: null
    },
    // Цена за паром
    isFerry: {
      default: false
    }
  },

  data() {
    return {
      types: [],
      job_types: [],
      regions: [],
      districts: [],
      meters: [],
      modalPrice: {
        id: null,
        region_id: null,
        district_ids: [],
        job_type_id: null,
        type_id: null,
        meter_ids: [],
        price: null,
        price_manager: null,
        price_mechanic: null,
        price_verifier: null,
        price_driver: null
      },
      isOpened: false,
      errors: {}
    }
  },

  methods: {
    onSubmit () {
      if (this.price.id) {
        PricesService.update(this.modalPrice)
          .then(() => this.$emit('close', true))
      } else {
        PricesService.add(this.modalPrice)
          .then(() => this.$emit('close', true))
          .catch(error => {
            if (error.response && 422 === error.response.status) {
              this.errors = error.response.data.errors
            }
          })
      }
    },

    onCancelClick() {
      this.$emit('close', false)
      this.isOpened = false
    },

    resetErrors () {
      this.errors = {}
    }
  },

  watch: {
    price (value) {
      if (value) {
        this.isOpened = true

        for (const [key] of Object.entries(this.modalPrice)) {
          if (key === 'district_ids') {
            this.modalPrice.district_ids = this.price.district_id ? [this.price.district_id] : []
          } else if (key === 'meter_ids') {
            this.modalPrice.meter_ids = this.price.meter_id ? [this.price.meter_id] : []
          } else {
            this.modalPrice[key] = this.price[key]
          }
        }
      } else {
        this.isOpened = false
      }
    }
  },

  beforeMount() {
    ListsService.get(['districts', 'job_types', 'regions', 'types', 'meters'])
      .then(({data}) => {
        this.job_types = data.job_types
        this.regions = data.regions
        this.districts = data.districts
        this.types = data.types
        this.meters = data.meters
      })
  },

  computed: {
    valid () {
      if (this.isFerry) {
        return this.modalPrice.price
      }

      return this.modalPrice.job_type_id &&
        this.modalPrice.type_id
        && this.modalPrice.region_id
        && this.modalPrice.district_ids.length > 0
        && this.modalPrice.meter_ids.length > 0
        && this.modalPrice.price
        && this.modalPrice.price_manager
        && this.modalPrice.price_mechanic
        && this.modalPrice.price_verifier
        && this.modalPrice.price_driver
    },

    // Районы отфильтрованные по региону
    filteredDistricts () {
      return this.modalPrice.region_id ? this.districts.filter(district => district.region_id === this.modalPrice.region_id) : []
    },

    // Счётчики отфильтрованные по типу
    filteredMeters () {
      return this.modalPrice.type_id ? this.meters.filter(meter => meter.type_id === this.modalPrice.type_id) : []
    }
  }
}
</script>
