<template>
  <div>
    <v-row>
      <v-col sm="10">
        <Heading title="Цены" />
      </v-col>
      <v-col
        align="right"
        align-self="center"
      >
        <v-btn
          color="blue"
          outlined
          @click="onAddClick"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="!isLoaded" :loading="!isLoaded" type="table-tbody" />

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-if="ferryPrice.id"
          label="Цена парома, Астрахань"
          :value="ferryPrice.price"
          disabled
        >
          <template v-slot:append-outer>
            <v-btn
              color="warning"
              class="mr-2"
              icon
              @click.prevent="onEditClick(ferryPriceId)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :items="prices"
      :headers="headers"
    >
      <template v-slot:item.region_id="{ item }">
        {{ item.region.name }}
      </template>
      <template v-slot:item.district_id="{ item }">
        {{ item.district ? item.district.name : '-' }}
      </template>
      <template v-slot:item.job_type_id="{ item }">
        {{ item.job_type.name }}
      </template>
      <template v-slot:item.type_id="{ item }">
        {{ item.type.name }}
      </template>
      <template v-slot:item.meter_id="{ item }">
        {{ item.meter ? item.meter.name : '-' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="warning"
          class="mr-2"
          icon
          @click.prevent="onEditClick(item.id)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
          color="error"
          icon
          @click.prevent="onDeleteClick(item.id)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <PriceModal
      :price="modalItem"
      :is-ferry="modalItem && ferryPriceId === modalItem.id"
      @close="onModalClose"
    />

    <v-dialog
      v-model="priceDeleteDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Вы уверены?
        </v-card-title>

        <v-card-text>
          Вы уверены что хотите удалить цену?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="priceDeleteDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error"
            @click="deletePrice"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { PricesService } from '@/common/api.service'
import PriceModal from '@/views/Director/Prices/PriceModal'

export default {
  components: {
    PriceModal
  },

  data() {
    return {
      // Цена парома
      ferryPrice: {},
      // Константа, ID цены парома
      ferryPriceId: 1000,
      prices: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      priceDeleteDialog: false,
      headers: [
        { text: 'Регион', value: 'region_id' },
        { text: 'Район', value: 'district_id' },
        { text: 'Тип', value: 'job_type_id' },
        { text: 'Тип', value: 'type_id' },
        { text: 'Тип счётчика', value: 'meter_id' },
        { text: 'Цена, руб', value: 'price' },
        { text: 'Менеджер, руб', value: 'price_manager' },
        { text: 'Слесарь, руб', value: 'price_mechanic' },
        { text: 'Поверщик, руб', value: 'price_verifier' },
        { text: 'Водитель, руб', value: 'price_driver' },
        { text: '', value: 'actions', align: 'end' }
      ]
    }
  },

  methods: {
    onAddClick() {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.priceDeleteDialog = true
    },

    onModalClose(needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.fetchPrices()
      }
    },

    // Удаление цены
    deletePrice () {
      this.priceDeleteDialog = false

      PricesService
        .delete(this.toDeleteId)
        .then(() => {
          this.fetchPrices()
        })
    },

    // Получение цен
    fetchPrices () {
      PricesService
        .get()
        .then(({data}) => {
          this.prices = data.filter(price => price.id !== this.ferryPriceId)
          this.ferryPrice = data.find(price => price.id === this.ferryPriceId)
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem() {
      if (this.toEditId === this.ferryPriceId && this.ferryPrice) {
        return this.ferryPrice
      }

      return this.toEditId !== 0 ? this.prices.find(user => user.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchPrices()
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
}
</style>
